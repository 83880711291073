const state = {
  errorModal: {},
  showErrorModal: false,
};

const getters = {
  errorModal: (state) => state.errorModal,
  showErrorModal: (state) => state.showErrorModal,
};

const actions = {
  setErrorModal({ commit }, errorModal) {
    commit('SET_ERROR_MODAL', errorModal);
    commit('SHOW_ERROR_MODAL', true);
  },

  cleanErrorModal({ commit }) {
    commit('CLEAN_ERROR_MODAL');
  },

  setShowErrorModal({ commit }, payload) {
    commit('SHOW_ERROR_MODAL', payload);
  },

  closeErrorModal({ commit, dispatch }) {
    commit('SHOW_ERROR_MODAL', false);
    dispatch('cleanErrorModal');
  }
};

const mutations = {
  SET_ERROR_MODAL(state, payload) {
    state.errorModal = payload;
  },

  CLEAN_ERROR_MODAL(state) {
    state.errorModal = {};
    state.showErrorModal = false;
  },

  SHOW_ERROR_MODAL(state, payload) {
    state.showErrorModal = payload;
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
