import api from '@/api/price-survey';

export default {
  state: {
    priceSurvey: null,
  },

  getters: {
    getPriceSurvey: state => state.priceSurvey,
  },

  actions: {
    async getAllPriceSurveys({ commit }) {
      try {
        const response = await api.getPriceSurveys();

        commit('SET_PRICE_SURVEY', response.data);

        return response.data;
      } catch (error) {
        commit('SET_PRICE_SURVEY', null);
      }
    },

    async getSinglePriceSurvey({ commit }, payload) {
      try {
        const response = await api.getPriceSurvey(payload);

        commit('SET_PRICE_SURVEY', response.data);

        return response.data;
      } catch (error) {
        commit('SET_PRICE_SURVEY', null);
      }
    },
  },

  mutations: {
    SET_PRICE_SURVEY(state, payload) {
      state.priceSurvey = payload;
    },
  }
};