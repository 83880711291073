export default {
  state: {
    showModal: null,
  },
  getters: {
    showModal: state => state.showModal,
  },
  actions: {
    setModalToShow({ commit }, payload) {
      commit('SET_MODAL_TO_SHOW', payload);
    },
    clearModalToShow({ commit }) {
      commit('CLEAR_MODAL_TO_SHOW');
    }
  },
  mutations: {
    // Set on the format:
    // Object: { name: String, Object or null...  }
    SET_MODAL_TO_SHOW(state, payload) {
      state.showModal = payload;
    },
    CLEAR_MODAL_TO_SHOW(state) {
      state.showModal = null;
    },
  }
};
