import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import store from '@/store/index';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true,
});

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        meta: {
          requiresAuth: false,
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
      },
    ],
  },
  {
    path: '/reclamacoes',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'NewReclamation',
        meta: {
          breadcrumb: [
            { name: 'BREADCRUMB.RECLAMATION.onlineRelation', path: '/reclamacao-online' },
            { name: 'BREADCRUMB.RECLAMATION.index', path: '/reclamacoes' },
          ],
        },
        component: () => import(/* webpackChunkName: "Complaint" */ '../views/Complaint.vue'),
      },
      {
        path: 'informacoes',
        name: 'ComplaintGeneralInfo',
        meta: {
          breadcrumb: [
            { name: 'BREADCRUMB.RECLAMATION.index', path: '/reclamacoes' },
            { name: 'BREADCRUMB.RECLAMATION.finish', path: 'informacoes' },
          ],
        },
        component: () =>
          import(/* webpackChunkName: "Complaint" */ '../views/ComplaintGeneralInfo.vue'),
      },
    ],
  },
  {
    path: '/minhas-reclamacoes',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'MyComplaints',
        meta: {
          breadcrumb: [
            { name: 'BREADCRUMB.MYCOMPLAINTS.onlineRelation', path: '/reclamacao-online' },
            { name: 'BREADCRUMB.MYCOMPLAINTS.index', path: '/minhas-reclamacoes' },
          ],
        },
        component: () =>
          import(/* webpackChunkName: "Complaint-index" */ '../views/MyComplaints/Index.vue'),
      },
      {
        path: ':complaintId',
        name: 'ComplaintDetails',
        props: (route) => ({ complaintId: String(route.params.complaintId) }),
        meta: {
          breadcrumb: [
            { name: 'BREADCRUMB.MYCOMPLAINTS.index', path: '/minhas-reclamacoes' },
            { name: 'BREADCRUMB.MYCOMPLAINTS.details', path: ':complaintId' },
          ],
        },
        component: () =>
          import(/* webpackChunkName: "Complaint-single" */ '../views/MyComplaints/Single.vue'),
      },
    ],
  },
  {
    path: '/codigo-defesa-consumidor',
    meta: {
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'ConsumerDefenseCode',
        meta: {
          breadcrumb: [{ name: 'BREADCRUMB.CONSUMER.index', path: '/codigo-defesa-consumidor' }],
        },
        component: () =>
          import(/* webpackChunkName: "Consumer" */ '../views/ConsumerDefenseCode/Index.vue'),
      },
      {
        path: '/codigo-defesa-consumidor/audio',
        name: 'ConsumerDefenseCodeAudio',
        meta: {
          breadcrumb: [
            { name: 'BREADCRUMB.CONSUMER.index', path: '/codigo-defesa-consumidor' },
            { name: 'BREADCRUMB.CONSUMER.audio', path: '/codigo-defesa-consumidor/audio' },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "Consumer" */ '../views/ConsumerDefenseCode/DefenseCodeAudio.vue'
          ),
      },
    ],
  },
  {
    path: '/ranking-reclamacoes',
    meta: {
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'ComplaintRanking',
        meta: {
          breadcrumb: [{ name: 'BREADCRUMB.RANKING.index', path: '/ranking-reclamacoes' }],
        },
        component: () =>
          import(/* webpackChunkName: "Ranking" */ '../views/ComplaintRanking/Index.vue'),
      },
    ],
  },
  {
    path: '/pesquisa-precos',
    meta: {
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'PriceSurvey',
        meta: {
          breadcrumb: [{ name: 'BREADCRUMB.PRICE.index', path: '/pesquisa-precos' }],
        },
        component: () =>
          import(/* webpackChunkName: "PriceSurvey" */ '../views/PriceSurvey/Index.vue'),
      },
      {
        path: ':id',
        name: 'PriceSurveyDetails',
        props: (route) => ({ priceSurveyId: String(route.params.id) }),
        meta: {
          breadcrumb: [
            { name: 'BREADCRUMB.PRICE.index', path: '/pesquisa-precos' },
            { name: 'BREADCRUMB.PRICE.details', path: ':id' },
          ],
        },
        component: () =>
          import(/* webpackChunkName: "PriceSurvey" */ '../views/PriceSurvey/Single.vue'),
      },
    ],
  },
  {
    path: '/noticias',
    meta: {
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'News',
        component: () => import(/* webpackChunkName: "News" */ '../views/News/Index.vue'),
      },
      {
        path: ':id',
        name: 'SingleNews',
        props: (route) => ({ id: String(route.params.id) }),
        component: () => import(/* webpackChunkName: "News" */ '../views/News/Single.vue'),
      },
    ],
  },
  {
    path: '/lista-de-atalhos',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'ShortcutList',
        component: () => import(/* webpackChunkName: "ShortcutList" */ '../views/ShortcutList.vue'),
        meta: {
          breadcrumb: [{ name: '', path: '/' }],
        },
      },
    ],
  },
  {
    path: '/faq',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'FAQ',
        component: () => import(/* webpackChunkName: "FAQ" */ '../views/FAQ.vue'),
        meta: {
          breadcrumb: [{ name: '', path: '/' }],
        },
      },
    ],
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: 'erro',
        name: 'Error',
        component: () => import(/* webpackChunkName: "Error" */ '../views/ErrorPage.vue'),
      },
    ],
  },
  {
    path: '/reclamacao-online',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'OnlineComplaint',
        component: () =>
          import(/* webpackChunkName: "OnlineComplaint" */ '../views/OnlineComplaint.vue'),
        meta: {
          breadcrumb: [{ name: '', path: '/' }],
        },
      },
    ],
  },
  {
    path: '/agendamento',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'AppointmentMain',
        meta: {
          title: 'Agendamento de Serviços',
          breadcrumb: [{ name: 'BREADCRUMB.APPOINTMENT.title', path: '/agendamento' }],
        },
        component: () =>
          import(
            /* webpackChunkName: "AppointmentMain" */ '../views/appointmentModule/AppointmentMain.vue'
          ),
      },
      {
        path: 'servico',
        name: 'ServiceAppointment',
        meta: {
          title: 'Agendamento de Serviços',
        },
        component: () =>
          import(
            /* webpackChunkName: "ServiceAppointment" */ '../views/appointmentModule/schedule/ServiceAppointment.vue'
          ),
      },
      {
        path: 'informacoes',
        name: 'FinishAppointment',
        component: () =>
          import(
            /* webpackChunkName: "FinishAppointment" */ '../views/appointmentModule/schedule/FinishAppointment.vue'
          ),
      },
      {
        path: 'consulta',
        name: 'AppointmentConsult',
        props: (route) => ({ cancelado: Boolean(route.query.cancelado) }),
        component: () => import(/* webpackChunkName: "appointment-index" */ '../views/appointmentModule/consult/Index.vue'),
      },
      {
        path: 'consulta/:appointmentId',
        name: 'appointmentSingle',
        props: (route) => ({ appointmentId: String(route.params.appointmentId) }),
        component: () => import(/* webpackChunkName: "appointment-detail" */ '../views/appointmentModule/consult/Single.vue'),
      },
    ],
  },
  {
    path: '/chamado',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'Manifestation',
        meta: {
          title: 'Chamado',
          breadcrumb: [{ name: 'BREADCRUMB.MANIFESTATION.title', path: '/chamado' }],
        },
        component: () =>
          import(
            /* webpackChunkName: "Manifestation" */ '../views/ManifestationModule/Index.vue'
          ),
      },
      {
        path: 'servico',
        name: 'ManifestationService',
        meta: {
          title: 'Serviço',
          breadcrumb: [
            { name: 'BREADCRUMB.MANIFESTATION.title', path: '/chamado' },
            { name: 'BREADCRUMB.MANIFESTATION.service', path: '/chamado/servico' },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "ManifestationService" */ '../views/ManifestationModule/Service.vue'
          ),
      },
      {
        path: 'servico/criar',
        name: 'MakeManifestation',
        meta: {
          title: 'Criar',
          breadcrumb: [
            { name: 'BREADCRUMB.MANIFESTATION.title', path: '/chamado' },
            { name: 'BREADCRUMB.MANIFESTATION.service', path: '/chamado/servico' },
            { name: 'BREADCRUMB.MANIFESTATION.create', path: '/chamado/servico/criar' },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "ManifestationService" */ '../views/ManifestationModule/MakeManifestation.vue'
          ),
      },
      {
        path: 'servico/criar/confirmar',
        name: 'FinishManifestation',
        meta: {
          title: 'Confirmar',
          breadcrumb: [
            { name: 'BREADCRUMB.MANIFESTATION.title', path: '/chamado' },
            { name: 'BREADCRUMB.MANIFESTATION.service', path: '/chamado/servico' },
            { name: 'BREADCRUMB.MANIFESTATION.create', path: '/chamado/servico/criar' },
            { name: 'BREADCRUMB.MANIFESTATION.confirm', path: '/chamado/servico/criar/confirmar' },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "ManifestationFinish" */ '../views/ManifestationModule/FinishManifestation.vue'
          ),
      },
      {
        path: 'servico/criar/confirmar/confirmado',
        name: 'TicketInfo',
        component: () =>
          import(
            /* webpackChunkName: "TicketInfo" */ '../views/ManifestationModule/TicketInfo.vue'
          ),
      },
      {
        path: 'solicitacoes',
        name: 'Manifestations',
        meta: {
          title: 'Solicitações',
          breadcrumb: [
            { name: 'BREADCRUMB.MANIFESTATION.title', path: '/chamado' },
            { name: 'BREADCRUMB.MANIFESTATION.list', path: '/chamado/solicitacoes' },
          ],
        },
        component: () =>
          import(
            /* webpackChunkName: "Manifestations" */ '../views/ManifestationModule/manifestations/Index.vue'
          ),
      },
      {
        path: ':solicitationProtocol',
        name: 'ManifestationSingle',
        props: (route) => ({ solicitationProtocol: String(route.params.solicitationProtocol) }),
        component: () =>
          import(
            /* webpackChunkName: "ManifestationSingle" */ '../views/ManifestationModule/manifestations/Single.vue'
          ),
      },
    ],
  },
  {
    path: '/avalie',
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'Survey',
        component: () => import(/* webpackChunkName: "Survey" */ '@/views/survey/Index.vue'),
      },
    ]
  },

  // --- Not found route
  {
    path: '*',
    component: () => import(/* webpackChunkName: "root" */ '../views/Base.vue'),
    children: [
      {
        path: '',
        name: 'ErrorNotFound',
        component: () => import(/* webpackChunkName: "Error" */ '../views/ErrorNotFound.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  let redirectPath = null;
  const redirectQuery = {};

  redirectQuery.redirect = to.fullPath;
  // Checking if the route requires auth
  if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.userToken) {
    store.dispatch('loginKeycloak', `${window.location.origin}`);
  } else if (redirectPath) {
    next({
      path: to.fullPath,
      query: redirectQuery
    });
  }

  next();
});

export default router;
