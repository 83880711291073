import api from '@/api/modals';

export default {
  state: {
    agreement: null,
    unfulfilled: null,
    answer: {
      response: null,
      attachments: [],
    },
    messageAnswer: null,
    attachmentsAnswer: null,
  },

  getters: {
    getAnswerAttachments: state => state.answer.attachments,
  },

  actions: {
    async confirmAgreement({ commit }, ComplaintId) {
      try {
        const response = await api.postAgreement(ComplaintId);

        commit('SET_AGREEMENT_RESPONSE', response);
      } catch (error) {
        commit('SET_AGREEMENT_RESPONSE', null);
      }
    },

    async agreementConfirmed({ commit }, ComplaintId) {
      try {
        const response = await api.postAgreementConfirmed(ComplaintId);

        commit('SET_AGREEMENT_RESPONSE', response);
      } catch (error) {
        commit('SET_AGREEMENT_RESPONSE', null);
      }
    },

    async refuseAgreement({ commit }, payload) {
      try {
        const response = await api.postRefuseAgreement(payload.id, {
          message: payload.message
        });

        commit('SET_UNFULFILLED_RESPONSE', response);
      } catch (error) {
        commit('SET_UNFULFILLED_RESPONSE', null);
      }
    },

    async unfulfilledAgreement({ commit }, payload) {
      try {
        const response = await api.postAgreementUnfulfilled(payload.id, {
          message: payload.message
        });

        commit('SET_UNFULFILLED_RESPONSE', response);
      } catch (error) {
        commit('SET_UNFULFILLED_RESPONSE', null);
      }
    },

    async answerContact({ commit }, payload) {
      const formData = new FormData();

      if(payload.message !== '') {
        formData.append('message', payload.message);
      } else {
        formData.append('message', 'Anexos solicitados enviados');
      }

      if(payload.attachments !== undefined) {
        payload.attachments.forEach(function (element, index) {
          formData.append(`attachments[${index}]`, element);
        });

        commit('SAVE_ATTACHMENTS', payload.attachments);
      }

      try {
        const response = await api.postAnswer(payload.id, formData);

        commit('SET_ANSWER_CONTACT', response);
      } catch (error) {
        commit('SET_ANSWER_CONTACT', null);
      }
    },

    async answerRefusedDocs({ commit }, payload) {
      const formData = new FormData();

      payload.attachments.forEach(function (element, index) {
        formData.append(`attachments[${index}]`, element);
      });

      try {
        const response = await api.postAnswerRefusedDocs(payload.id, formData);

        commit('SET_ANSWER_CONTACT', response);
      } catch (error) {
        commit('SET_ANSWER_CONTACT', null);
      }
    },
  },

  mutations: {
    SET_AGREEMENT_RESPONSE(state, payload) {
      state.agreement = payload;
    },

    SET_UNFULFILLED_RESPONSE(state, payload) {
      state.unfulfilled = payload;
    },

    SET_ANSWER_CONTACT(state, payload) {
      state.answer.response = payload;
    },

    SAVE_ATTACHMENTS(state, payload) {
      state.answer.attachments = payload;
    }
  }
};