import api from '@/api/news';

export default {
  state: {
    news: [],
    single: null
  },

  getters: {
    news: state => state.news,
    singleNews: state => state.single
  },

  actions: {
    async getNews({ commit }) {
      try {
        const response = await api.getNews();

        commit('SET_NEWS', response.data.itens);

        return response.data.itens;
      } catch (error) {
        commit('SET_NEWS', null);
      }
    },

    async getSingleNews({ commit }, newsId) {
      try {
        const response = await api.getSingleNews(newsId);

        commit('SET_SINGLE_NEW', response.data.data);

        return response.data.data;
      } catch (error) {
        commit('SET_SINGLE_NEW', null);
      }
    }
  },

  mutations: {
    SET_NEWS(state, payload) {
      state.news = payload;
    },

    SET_SINGLE_NEW(state, payload) {
      state.single = payload;
    }
  }
};
