import api from '@/api/appointment';
import  router  from '../../router';

export default {
    state: {
        lists: {
            categories: null,
            services: null,
            places: null,
            availableDates: null,
            availableHoursDetails: null,
            vacanciesValues: null,
        },
        appointmentData: {
            category: null,
            service: null,
            appointmentType: null,
            place: null,
            date: null,
            hour: null,
        },
        finishAppointment: {
            status: null,
            orientacoes: null,
            errorMsg: null,
            id: null,
        },
        backStepChange: false,
        currentStep: 1,
        stepsIsCompleted: {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
        },
        userInfo: null,
        sendAppointment: false,
    },

    getters: {
        availableDates: (state) => state.lists.availableDates,
        availableHoursDetails: (state) => state.lists.availableHoursDetails,
        categories: (state) => state.lists.categories,
        services: (state) => state.lists.services,
        places: (state) => state.lists.places,
        appointmentData: (state) => state.appointmentData,
        selectedCategory: (state) => state.appointmentData.category,
        selectedService: (state) => state.appointmentData.service,
        selectedPlace: (state) => state.appointmentData.place,
        selectedDate: (state) => state.appointmentData.date,
        selectedHour: (state) => state.appointmentData.hour,
        selectedAppointmentType: (state) => state.appointmentData.appointmentType,
        selectedServiceAddress: (state) => state.appointmentData?.service?.campos_dinamicos.filter((field) => field.tipo === 'endereco') ?? null,
        selectedServiceFiles: (state) => state.appointmentData?.service?.campos_dinamicos.filter((field) => field.tipo === 'file') ?? null,
        finishAppointment: (state) => state.finishAppointment,
        availableDatesIsEmpty: (state) => state.lists.availableDates?.length === 0 ? true : false,
        backStepChange: (state) => state.backStepChange,
        vacanciesValues: (state) => state.lists.vacanciesValues,
        userAppointmentInfo: (state) => state.userInfo,
        currentStep: (state) => state.currentStep,
        stepsIsCompleted: (state) => state.stepsIsCompleted,
        sendAppointment: (state) => state.sendAppointment,
    },

    actions: {
        async loadCategories({ commit }) {
            return api.getCategories()
            .then((response) => {
                let list = [...response.data];

                commit('SET_CATEGORIES_LIST', list);

                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error.response));
        },
        loadServices({ commit, state }){
            return api.getServices(state.appointmentData.category.id)
            .then((response) => {
                let list = [];

                if(response !== [])
                    list = [...response.data];

                commit('SET_SERVICES_LIST', list);

                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error.response));
        },
        loadPlaces({ commit, state }) {
            if(state.appointmentData.service)
                return api.listServicePlaces(state.appointmentData.service.id)
                .then((response) => {
                    let list = [...response.data];

                    commit('SET_PLACES_LIST', list);

                    return Promise.resolve(response);
                })
                .catch((error) => Promise.reject(error.response));
        },
        loadAvailableDates({ commit, state }){
            return api.datesWithAvailableScheduleOfPlace(state.appointmentData.service.id,
                state.appointmentData.place.id, state.appointmentData.appointmentType.id)
            .then((response) => {
                let list = response.data.map(el => el.dia);

                commit('SET_AVAILABLE_DATES_LIST', list);

                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error.response));
        },
        loadAvailableHours({ commit, state }){
            return api.availableDateScheduleOfPlace(state.appointmentData.service.id,
                state.appointmentData.date, state.appointmentData.place.id, state.appointmentData.appointmentType.id)
            .then((response) => {
                let list = [...response.data];

                commit('SET_AVAILABLE_HOURS_LIST', list);

                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error.response));
        },
        loadAvailableNumberOfVacancies({ state, commit }){
            return api.numberOfAvailableHoursOfAPlace(state.appointmentData.service.id,
                state.appointmentData.appointmentType.id)
            .then((response) => {
                let list = [...response.data];

                commit('SET_AVAILABLE_NUMBER_OF_VACANCIES', list);

                return Promise.resolve(response);
            })
            .catch((error) => Promise.reject(error.response));
        },
        saveSelectedCategory ({ state, commit }, category){
            state.stepsIsCompleted[1] = true,
            commit('SET_SELECTED_CATEGORY', category);
        },
        saveSelectedService({ commit }, service){
            commit('SET_SELECTED_SERVICE',
            {
                id: service.id,
                nome: service.nome,
                orientacoes: service.orientacoes,
                orientacoes2: service.descricao,
                campos_dinamicos: service?.campos_dinamicos,
                confirmacao_obrigatoria: service?.confirmacao_obrigatoria,
                dias_confirmacao: service?.dias_confirmacao,
            });
        },
        saveSelectedPlace({ commit }, place){
            commit('SET_SELECTED_PLACE', { id: place.id, description: place.nome, endereco: place.endereco, telefone: place.telefone });
        },
        saveUserAddress({ state, commit }, address) {
            if(!state.appointmentData.service?.servico_campo_dinamico_resposta)
                state.appointmentData.service.servico_campo_dinamico_resposta = {};

            commit('SET_USER_ADDRESS', address);
        },
        saveUserFiles({ state, commit }, files) {
            if(!state.appointmentData.service.servico_campo_dinamico_resposta)
                state.appointmentData.service.servico_campo_dinamico_resposta = {};

            commit('SET_USER_FILES', files);
        },
        saveSelectedUserInfos({ commit }, infos){
            commit('SET_SELECTED_USER_INFOS', infos);
        },
        saveSelectedAppointmentType({ commit, dispatch }, appointmentType){
            commit('SET_SELECTED_APPOINTMENT_TYPE', appointmentType);
            dispatch('loadAvailableNumberOfVacancies');
        },
        saveSelectedDate({ commit }, date){
            commit('SET_SELECTED_DATE', date);
        },
        saveSelectedHour({ commit }, hour){
            commit('SET_SELECTED_HOUR', { id: hour.id, description: hour.nome });
        },
        saveCurrentStep({ state, commit }, step) {
            if(step === 5 && !state.stepsIsCompleted[5])
                state.stepsIsCompleted[4] = true,
                state.stepsIsCompleted[5] = true;

            commit('SET_CURRENT_STEP', step);
        },
        postAppointment({ state, commit }, { appointmentUser }){
            const formData = new FormData();

            formData.append('origem', 'frontend');

            formData.append('agendamento_proprio', appointmentUser.selfAppointment ? 1 : 0);
            if (state.appointmentData.appointmentType !== null)
                formData.append('tipo_id', state.appointmentData.appointmentType.id);
            if (appointmentUser.name !== null)
                formData.append('nome', appointmentUser.name);
            if (appointmentUser.birthdate !== null)
                formData.append('data_nascimento', appointmentUser.birthdate);
            if (appointmentUser.cpf !== null)
                formData.append('cpf', appointmentUser.cpf);
            if (appointmentUser.phone !== null)
                formData.append('telefone', appointmentUser.phone);
            if(state.appointmentData.service?.servico_campo_dinamico_resposta) {
                let dynamicFields = state.appointmentData.service?.servico_campo_dinamico_resposta;

                Object.keys(dynamicFields).forEach((key) => {
                    if(dynamicFields[key].length > 0)
                        dynamicFields[key].forEach((field, index) => {
                            formData.append(`servico_campo_dinamico_resposta[${key}][${index}]`, field);
                        });
                    else formData.append(`servico_campo_dinamico_resposta[${key}]`, JSON.stringify(dynamicFields[key]));
                });
            }

            return api.postAppointment(state.appointmentData, formData)
            .then((response) => {
                commit('SET_USER_APPOINTMENT_INFO', appointmentUser);
                commit('SET_FINISH_STATUS',
                    {
                        status: 'Completed',
                        orientacoes: state.appointmentData.service.orientacoes,
                        description: state.appointmentData.service.orientacoes2,
                        birthdate: response.data.data_nascimento,
                        cpf: response.data.cpf,
                        date: response.data.data,
                        id: response.data.id,
                        name: response.data.nome,
                        phone: response.data.telefone,
                        servico_campo_dinamico_resposta:
                        state.appointmentData.service.servico_campo_dinamico_resposta
                    });

                router.push({ name: 'FinishAppointment' });

                return Promise.resolve(response);
            })
            .catch((error) => {
                commit('SET_USER_APPOINTMENT_INFO', null);

                return Promise.reject(error.response);
            });
        },
        resetAppointmentData({ commit }){
            commit('RESET_APPOINTMENT_DATA');
            commit('SET_SEND_APPOINTMENT');
        },
        resetAppointmentField({ commit }, field){
            commit('RESET_APPOINTMENT_FIELD', field);
        },
        resetFinishData({ commit }){
            commit('RESET_FINISH_DATA');
        },
        routeStepChange({ commit }, route){
            commit('SET_BACK_STEP_CHANGE', route);
        },
        resetAppointmentList({ commit }, field) {
            commit('RESET_APPOINTMENT_LIST_FIELD', field);
        },
        resetCompletedList({ commit }) {
            commit('RESET_COMPLETED_LIST');
        },
        setSendAppointment({ commit }, payload) {
            commit('SET_SEND_APPOINTMENT', payload);
        },
        getAddressServiceDynamicField({ state, getters }) {
            if(!getters.selectedServiceAddress ||
                !state.appointmentData?.service?.servico_campo_dinamico_resposta
            ) return null;

            return state.appointmentData?.service
                ?.servico_campo_dinamico_resposta[getters.selectedServiceAddress[0].id];
        },
        getFilesServiceDynamicField({ state, getters }) {
            if(!getters.selectedServiceAddress ||
                !state.appointmentData?.service?.servico_campo_dinamico_resposta
            ) return null;

            return state.appointmentData?.service?.servico_campo_dinamico_resposta[getters.selectedServiceFiles[0].id];
        }
    },
    mutations: {
        SET_CATEGORIES_LIST(state, list){
            state.lists.categories = list;
        },
        SET_SERVICES_LIST(state, services){
            state.lists.services = services;
            state.lists.availableDates = null;
            state.lists.vacanciesValues = null;
        },
        SET_PLACES_LIST(state, places){
            let list = places;

            if(state.lists.vacanciesValues)
                state.lists.places = list.map(el => {
                    const item = state.lists.vacanciesValues?.find(e => e.unidade_id === el.id);
                    const vacancies = item?.total_horarios;
                    const maxVacancies = vacancies ?? '0';

                    return { ...el, maxVacancies };
                });
            else
                state.lists.places = list;
        },
        SET_AVAILABLE_DATES_LIST(state, dates){
            state.lists.availableDates = dates;
            state.lists.availableHoursDetails = null;
        },
        SET_AVAILABLE_HOURS_LIST(state, hours){
            state.lists.availableHoursDetails = hours;
        },
        SET_SELECTED_CATEGORY(state, category){
            if(state.appointmentData.category !== category) {
                state.appointmentData.category = category;
                state.stepsIsCompleted[1] = true;
                state.stepsIsCompleted[2] = false;
                state.stepsIsCompleted[3] = false;
                state.stepsIsCompleted[4] = false;
                state.stepsIsCompleted[5] = false;
                state.appointmentData.service = null;
            }
        },
        SET_SELECTED_SERVICE(state, service){
            state.appointmentData.service = { ...state.appointmentData.service, ...service };
            state.appointmentData.appointmentType = null;
            state.lists.places = null;
            state.lists.vacanciesValues = null;
            state.stepsIsCompleted[2] = false;
            state.stepsIsCompleted[3] = false;
            state.stepsIsCompleted[4] = false;
            state.stepsIsCompleted[5] = false;
        },
        SET_SELECTED_APPOINTMENT_TYPE(state, type){
            state.appointmentData.appointmentType = type;
            state.lists.vacanciesValues = null;
            state.stepsIsCompleted[2] = true;
            state.stepsIsCompleted[3] = false;
            state.stepsIsCompleted[4] = false;
            state.stepsIsCompleted[5] = false;
        },
        SET_SELECTED_PLACE(state, value){
            if(state.appointmentData.place !== value)
                state.lists.availableDates = null,
                state.lists.availableHoursDetails = null;

            state.appointmentData.place = value;
            state.appointmentData.date = null;
            state.stepsIsCompleted[3] = true;
            state.stepsIsCompleted[4] = true;
            state.stepsIsCompleted[5] = false;
        },
        SET_SELECTED_USER_INFOS(state, value){
            state.userInfo = value;
            state.stepsIsCompleted[3] = true;
        },
        SET_SELECTED_DATE(state, date){
            state.appointmentData.date = date;
            state.appointmentData.hour = null;
            state.stepsIsCompleted[3] = true;
            state.stepsIsCompleted[4] = false;
            state.stepsIsCompleted[5] = false;
        },
        SET_SELECTED_HOUR(state, hour){
            state.appointmentData.hour = hour;
            state.stepsIsCompleted[3] = true;
            state.stepsIsCompleted[4] = false;
            state.stepsIsCompleted[5] = false;
        },
        SET_FINISH_STATUS(state, payload){
            state.finishAppointment.status = payload.status;
            state.finishAppointment.orientacoes = payload.orientacoes;
            state.finishAppointment.description = payload.description;
            state.finishAppointment.errorMsg = payload.errorMsg;

            state.finishAppointment.birthdate = payload.birthdate;
            state.finishAppointment.cpf = payload.cpf;
            state.finishAppointment.date = payload.date;
            state.finishAppointment.id = payload.id;
            state.finishAppointment.name = payload.name;
            state.finishAppointment.phone = payload.phone;
            state.finishAppointment.servico_campo_dinamico_resposta = payload.servico_campo_dinamico_resposta;
        },
        SET_STEP_COMPLETED_STATUS(state, { step, value }){
            state.stepsIsCompleted[step] = value;
        },
        SET_AVAILABLE_NUMBER_OF_VACANCIES(state, payload) {
            state.lists.vacanciesValues = payload;
            state.lists.places = state.lists.places?.map(el => {
                const item = payload?.find(e => e.unidade_id === el.id);
                const vacancies = item?.total_horarios;
                const maxVacancies = vacancies ?? '0';

                return { ...el, maxVacancies };
            });
        },
        RESET_APPOINTMENT_FIELD(state, field) {
            state.appointmentData[field] = null;
        },
        RESET_APPOINTMENT_DATA(state){
            Object.keys(state.appointmentData).forEach((key)=>{
                state.appointmentData[key] = null;
            });
            Object.keys(state.lists).forEach((key) => {
                state.lists[key] = null;
            });
        },
        RESET_APPOINTMENT_LIST_FIELD(state, field) {
            state.lists[field] = null;
        },
        RESET_FINISH_DATA(state){
            Object.keys(state.finishAppointment).forEach((key)=>{
                state.finishAppointment[key] = null;
            });
        },
        RESET_COMPLETED_LIST(state){
            [1,2,3,4,5].forEach(el=> state.stepsIsCompleted[el] = false);
        },
        RESET_COMPLETED_LIST_STEP(state, step){
            state.stepsIsCompleted[step] = false;
        },

        SET_BACK_STEP_CHANGE(state, route){
            state.backStepChange = route;
        },

        SET_SEND_APPOINTMENT(state, payload){
            state.sendAppointment = payload;
        },

        SET_USER_APPOINTMENT_INFO(state, payload){
          state.userInfo = payload;
          state.stepsIsCompleted[3] = true;
          state.stepsIsCompleted[4] = true;
          state.stepsIsCompleted[5] = true;
        },

        SET_CURRENT_STEP(state, payload){
            state.currentStep = payload;
        },
        SET_USER_ADDRESS(state, payload) {
            const addressId = Object.keys(payload)[0];

            state.appointmentData.service.servico_campo_dinamico_resposta[addressId] = payload[addressId];
         },
        SET_USER_FILES(state, payload) {
            const filesId = Object.keys(payload)[0];

            if(payload[filesId].length === 0)
                delete state.appointmentData.service.servico_campo_dinamico_resposta[filesId];
            else
                state.appointmentData.service.servico_campo_dinamico_resposta[filesId] = payload[filesId];
        }
    }
};
