import Keycloak from '@/api/keycloak.js';
import { setAuthHeader, resetAuthHeader } from '@/config/axios/index';

export default {
    state: {
      userInfo: {
        name: null,
        email: null,
        cpf: null,
        gender: null,
        birthdate: null,
        phone: null,
        userId: null,
        avatar: null,
      },
        authenticated: false,
        userToken: null,
        keycloak: null,
        clientId: process.env.VUE_APP_KEYCLOAK_REDIRECT,
    },

    getters: {
        userData: (state) => state.userInfo,
        userName: (state) =>  state.userInfo.name,
        userCPF: (state) => state.userInfo.cpf,
        userIsLogged: (state) => state.authenticated,
        userToken: (state) => state.userToken,
        userId: (state) => state.userId,
        userGender: (state) => state.userInfo.gender,
        clientId: (state) => state.clientId,
        verifyClientId: (state) => state.clientId === process.env.VUE_APP_KEYCLOAK_CLIENTID,
        //keycloakOnLoadConfig: (state) => state.keycloak &&
        //state.keycloak.loginRequired === undefined ? 'check-sso' : 'login-required',
    },

    actions: {
      async initKeycloak({ commit, dispatch }, onload = '' ){
        try{
          const userInfo = await Keycloak.initKeycloak(onload);

          commit('KEYCLOAK_INIT', userInfo);

          if(userInfo.authenticated){
            commit('KEYCLOAK_LOGIN', userInfo);
            dispatch('setClientId', process.env.VUE_APP_KEYCLOAK_CLIENTID);
          }
        }
        catch (e){
          commit('KEYCLOAK_INIT', null);
        }
      },

      async autologinKeycloak({ commit }, { token, refreshToken, clientId }) {
        try{
          const userInfo = await Keycloak.autologinKeycloak(token, refreshToken, clientId);

          commit('KEYCLOAK_INIT', userInfo);

          if(userInfo.authenticated){
            commit('KEYCLOAK_LOGIN', userInfo);
          }
        }
        catch (e){
          commit('KEYCLOAK_INIT', null);
        }

      },

      async loginKeycloak({ commit, state }, redirectUri = process.env.VUE_APP_KEYCLOAK_REDIRECT){
        try{
          const userInfo = await state.keycloak.login(
            { redirectUri: redirectUri }
          );

          commit('KEYCLOAK_LOGIN', userInfo);
        }
        catch (e){
          commit('KEYCLOAK_LOGIN', null);
          }
      },

      async setClientId({ commit }, clientId) {
        commit('CLIENT_ID', clientId);
      },

      async registerKeycloak({ state }){
        await state.keycloak.register({
            redirectUri: process.env.VUE_APP_KEYCLOAK_REDIRECT,
        });
      },

      changeKeycloakOnload({ commit }, loginRequired){
        commit('KEYCLOAK_CHANGE_REQUIRED_LOGIN', loginRequired);
      },

      async userLogout({ commit, dispatch, state }){
        await dispatch('initKeycloak');

        Promise.allSettled([
          // Reset user data
          commit('KEYCLOAK_USER_LOGOUT'),
              // Reset chat data
          dispatch('resetAppointmentData'),
              // Reset inpeace play data
          dispatch('resetFinishData'),
        ]);

        // Removing authorization from api requests
        resetAuthHeader();

        await state.keycloak.logout();
      },
    },

    mutations: {
      KEYCLOAK_INIT(state, userInfo){
        state.keycloak = userInfo.keycloak;
      },

      KEYCLOAK_LOGIN(state, userInfo){
        if (userInfo) {
          setAuthHeader(userInfo.userToken);
          state.userInfo.name = userInfo.name;
          state.userInfo.email = userInfo.email;
          state.userInfo.cpf = userInfo.cpf;
          state.userInfo.phone = userInfo.phone;
          state.userInfo.birthdate = userInfo.birthdate;
          state.userInfo.userId = userInfo.userId;
          state.userInfo.avatar = userInfo.avatar;
          state.userInfo.gender = userInfo.gender;
          state.authenticated = userInfo.authenticated;
          state.userToken = userInfo.userToken;
          state.keycloak = userInfo.keycloak;
        }
      },

      KEYCLOAK_USER_LOGOUT(state){
        state.userInfo.name = null;
        state.authenticated = false;
        state.userToken = null;
      },

      KEYCLOAK_CHANGE_REQUIRED_LOGIN(state, loginRequired){
        state.keycloak.loginRequired = loginRequired;
      },

      KEYCLOAK_UPDATE_USER_TOKEN(state, payload){
        setAuthHeader(payload);
        state.userToken = payload;
      },

      CLIENT_ID(state, payload) {
        state.clientId = payload;
      }
    }
};
