import api from '@/api/complaints';

export default {
  state: {
    complaints: null,
    complaint: null,
    complaintData: {
      company_name: null,
      company_cnpj: null,
      company_phone: null,
      company_email: null,
      establishment_type: null,
      company_place: null,
      consumer_company_request: null,
      description: null,
      rg_front: null,
      rg_back: null,
      register_document: null,
      proof_of_address: null,
      attachments: []
    },
    acceptance: null,
    finishComplaint: {
      code: null,
      status: null,
      company_name: null,
      description: null,
      updated_at: null
    },
    establishmentTypes: [],
    userData: {}
  },
  getters: {
    // --- Complaints --- //
    getComplaints: state => state.complaints,
    getComplaint: state => state.complaint,
    getComplaintData: state => state.complaintData,
    setEstablishmentTypes: state => state.establishmentTypes,
    getCompanyDataName: state => state.complaintData.company_name,
    getCompanyDataDescription: state => state.complaintData.description,

    // --- FinishComplaint --- //
    getFinishComplaint: state => state.finishComplaint,
    companyName: state => state.finishComplaint.company_name,
    description: state => state.finishComplaint.description,
    code: state => state.finishComplaint.code,
    updateDate: state => state.finishComplaint.updated_at,
    status: state => state.finishComplaint.status,
    idComplaint: state => state.finishComplaint.id,
  },
  actions: {
    async getAllComplaints({ commit }) {
      try {
        const response = await api.getComplaints();

        commit('SET_COMPLAINTS', response.data);

        return response.data;
      } catch (error) {
        commit('SET_COMPLAINTS', null);
      }
    },

    async getSingleComplaint({ commit }, payload) {
      return await api.getComplaint(payload).then((response) => {
        commit('SET_COMPLAINT', response.data);

        return Promise.resolve(response.data);

      }).catch((error) => {
        commit('SET_COMPLAINT', null);

        return Promise.reject(error.response);

      });
    },

    postComplaint({ state, commit }){
      const formData = new FormData();

      formData.append('company_name', state.complaintData.company_name);
      if (state.complaintData.company_cnpj !== null) {
        formData.append('company_cnpj', state.complaintData.company_cnpj);
      }
      if (state.complaintData.company_phone !== null) {
        formData.append('company_phone', state.complaintData.company_phone);
      }
      if (state.complaintData.company_email !== null) {
        formData.append('company_email', state.complaintData.company_email);
      }
      formData.append('establishment_type', state.complaintData.establishment_type);

      if (state.complaintData.establishment_type === 'ONLINE') {
        formData.append('purchase_platform', state.complaintData.company_place);
      } else {
        formData.append('establishment_address', state.complaintData.company_place);
      }

      if (state.complaintData.consumer_company_request !== null) {
        formData.append('consumer_company_request', state.complaintData.consumer_company_request);
      }
      formData.append('description', state.complaintData.description);
      formData.append('document_front', state.complaintData.rg_front);
      formData.append('document_verse', state.complaintData.rg_back);
      if (state.complaintData.register_document !== null) {
        formData.append('register_document', state.complaintData.register_document);
      }
      formData.append('proof_of_address', state.complaintData.proof_of_address);
      state.complaintData.attachments.forEach(function (element, index) {
        formData.append(`attachments[${index}]`, element);
      });

      return api.postComplaint(formData)
      .then((response) => {

        commit('SET_FINISH_COMPLAINT', response.data);

        return Promise.resolve(response);
      })
      .catch((error) => Promise.reject(error.response) );
    },

    setUserData({ commit }, userData){
      const form = new FormData();

      Object.keys(userData).forEach(key => form.append(key, userData[key]));
      commit('SET_USER_DATA', form);
    },

    async postUserData({ state }) {
      return await api.postUserData(state.userData);
    },

    saveCompanyName({ commit }, companyName) {
      commit('SET_COMPANY_NAME', companyName);
    },

    saveCompanyCnpj({ commit }, companyCnpj) {
      commit('SET_COMPANY_CNPJ', companyCnpj);
    },

    saveCompanyPhone({ commit }, CompanyPhone) {
      commit('SET_COMPANY_PHONE', CompanyPhone);
    },

    saveCompanyEmail({ commit }, CompanyEmail) {
      commit('SET_COMPANY_EMAIL', CompanyEmail);
    },

    saveEstablishmentType({ commit }, EstablishmentType) {
      commit('SET_ESTABLISHMENT_TYPE', EstablishmentType);
    },

    saveCompanyPlace({ commit }, companyPlace) {
      commit('SET_COMPANY_PLACE', companyPlace);
    },

    saveConsumerCompanyRequest({ commit }, ConsumerCompanyRequest) {
      commit('SET_CONSUMER_COMPANY_REQUEST', ConsumerCompanyRequest);
    },

    saveDescription({ commit }, description) {
      commit('SET_DESCRIPTION', description);
    },

    saveRgFront({ commit }, rgFront) {
      commit('SET_RG_FRONT', rgFront);
    },

    saveRgBack({ commit }, rgBack) {
      commit('SET_RG_BACK', rgBack);
    },

    saveRegisterDocument({ commit }, registerDocument) {
      commit('SET_REGISTER_DOCUMENT', registerDocument);
    },

    saveProofAddress({ commit }, proofAddress) {
      commit('SET_PROOF_ADDRESS', proofAddress);
    },

    saveAttachments({ commit }, attachments) {
      commit('SET_ATTACHMENTS', attachments);
    },

    removeAttachment({ commit }, position) {
      commit('REMOVE_ATTACHMENT', position);
    },

    resetComplaints({ commit }) {
      commit('SET_COMPLAINTS', null);
      commit('SET_COMPLAINT', null);
      commit('RESET_COMPLAINT_DATA');
      commit('RESET_FINISH_COMPLAINT');
    },

    resetComplaintData({ commit }) {
      commit('RESET_COMPLAINT_DATA');
    },

    resetFinishComplaint({ commit }) {
      commit('RESET_FINISH_COMPLAINT');
    },

    async getEstablishmentTypes({ commit }) {
      try {
        const response = await api.getEstablishmentType();

        commit('GET_ESTABLISHMENT_TYPES', response.data.establishment_type);
      } catch (error) {
        commit('GET_ESTABLISHMENT_TYPES', null);
      }
    },

    async fetchUserDocuments(userId) {
      try {
        const response = await api.getUserDocuments(userId);

        return response;

      } catch (error) {
        return error;
      }
    },

    complaintMockData() {
      return {
        'documentsList': {
          'userData': [
            {
              name: 'rgFront',
              label: 'Documento de identificação com foto (frente) *',
              files: [],
              isMultiple: false,
              required: true,
            },
            {
              name: 'rgBack',
              label: 'Documento de identificação com foto (verso) *',
              files: [],
              isMultiple: false,
              required: true,
            },
            {
              name: 'registerDocument',
              label: 'Documento com CPF *',
              files: [],
              isMultiple: false,
              required: true,
            },
            {
              name: 'proofAddress',
              label: 'Comprovante de residência',
              files: [],
              isMultiple: false,
              required: true,
            }
          ],
          'companyData': [
            {
              name: 'attachments',
              label: 'Comprovantes',
              files: [
                { name: 'foto01.jpg' },
                { name: 'Foto-da-carne.jpg' },
              ],
              isMultiple: true,
              required: true,
            }
          ]
        },
        'textsList': {
          'companyData': [
            {
              name: 'companyName',
              label: 'Nome da empresa: *',
              type: 'textInput',
              files: 'Supermercado',
              required: true,
            },
            {
              name: 'happend',
              label: 'Descreva o ocorrido *',
              type: 'textArea',
              files: 'Comprei uma carne e ela estava estragada, fui ao estabelecimento e não resolveram meu problema.',
              required: true,
            },
          ]
        }
      };
    },
    setFinishMockData({ commit }) {
      commit('SET_COMPANY_NAME', 'Supermercado');
      commit('SET_DESCRIPTION', 'Comprei uma carne e ela estava estragada, fui ao estabelecimento e não resolveram meu problema.');
      commit('SET_FINISH_COMPLAINT',
        {
          code: `0001.${new Date().getFullYear()}`,
          status: 'Verificando documentos enviados',
          company_name: 'Supermercado',
          description: 'Comprei uma carne e ela estava estragada, fui ao estabelecimento e não resolveram meu problema.',
          created_at: new Date(),
          updated_at: new Date(),
        }
      );
    },
    setDetailsMockData() {
      return {
        code: `0001.${new Date().getFullYear()}`,
        company_name: 'Supermercado',
        description: 'Comprei uma carne e ela estava estragada, fui ao estabelecimento e não resolveram meu problema.',
        status: 'Aguardando resposta do usuário',
        need_answer: null,
        created_at: new Date(),
        updated_at: new Date(),
        histories: [
          {
            hearing: null,
            description: 'Recebemos a resposta da empresa. Por favor, avalie a resposta em anexo e nos informe se está de acordo e solucionou a demanda',
            attachments: [
              {
                url: '',
                original_name: 'foto02.jpg'
              }
            ],
            status: 'Aguardando resposta do usuário',
            need_attach_file: false,
            created_at: new Date(),
            updated_at: new Date()
          },
          {
            hearing: null,
            description: 'Olá! Enviamos a notificação para a empresa solicitando esclarecimentos e acordo. O prazo pra resposta é de 11 dias corridos. Por favor, aguarde que entraremos em contato com você ao final desse prazo. O prazo oficial para a empresa dar uma resposta é dia 24/05/2022. Se ela entrou em contato diretamente com você e resolveu o seu problema, confirme abaixo.',
            attachments: [],
            status: 'Aguardando resposta da empresa',
            need_attach_file: false,
            created_at: new Date(),
            updated_at: new Date()
          },
          {
            hearing: null,
            description: 'Reclamação registrada',
            attachments: [],
            status: 'Reclamação registrada no Pro Consumidor',
            need_attach_file: false,
            created_at: new Date(),
            updated_at: new Date()
          },
          {
            hearing: null,
            description: 'Estamos gratos por você ter entrado em contato com o Admin Procon! Sua reclamação foi registrada e em breve você será notificado sobre as atualizações da sua reclamação.',
            attachments: [],
            status: 'Aguardando registro no Pro Consumidor',
            need_attach_file: false,
            created_at: new Date(),
            updated_at: new Date()
          },
          {
            hearing: null,
            description: 'Verificando documentos enviados',
            attachments: [
              {
                url: '',
                original_name: 'foto01.jpg'
              }
            ],
            status: 'Verificando documentos enviados',
            need_attach_file: false,
            created_at: new Date(),
            updated_at: new Date()
          }
        ],
        attachments: [
          {
            name: '1652463442_hScqXqU94GkiFhVy.png',
            original_name: 'foto02.jpg',
            url: '',
            refused: false
          },
          {
            name: '1652463379_y55AQM0Yxj5o2PMD.png',
            original_name: 'foto01.jpg',
            url: '',
            refused: false
          }
        ]
      };
    },
    setListMockData() {
      var mock = [];

      for (let index = 0; index < 5; index++) {
        mock.push(
          {
            code: `0001.${new Date().getFullYear()}`,
            created_at: new Date(),
            company_name: 'Supermercado',
            status: 'Aguardando resposta do usuário',
          }
        );
      }

      return mock;
    }
  },
  mutations: {
    // Set on the format:
    // Object: { name: String, Object or null...  }
    SET_COMPLAINTS(state, payload) {
      state.complaints = payload;
    },

    SET_COMPLAINT(state, payload) {
      state.complaint = payload;
    },

    SET_USER_DATA(state, payload) {
      state.userData = payload;
    },

    ACCEPT_AGREEMENT(state, payload) {
      state.acceptance = payload;
    },

    SET_COMPANY_NAME(state, payload) {
      state.complaintData.company_name = payload;
    },

    SET_COMPANY_CNPJ(state, payload) {
      state.complaintData.company_cnpj = payload;
    },

    SET_COMPANY_PHONE(state, payload) {
      state.complaintData.company_phone = payload;
    },

    SET_COMPANY_EMAIL(state, payload) {
      state.complaintData.company_email = payload;
    },

    SET_ESTABLISHMENT_TYPE(state, payload) {
      state.complaintData.establishment_type = payload;
    },

    SET_COMPANY_PLACE(state, payload) {
      state.complaintData.company_place = payload;
    },

    SET_CONSUMER_COMPANY_REQUEST(state, payload) {
      state.complaintData.consumer_company_request = payload;
    },

    SET_DESCRIPTION(state, payload) {
      state.complaintData.description = payload;
    },

    SET_RG_FRONT(state, payload) {
      state.complaintData.rg_front = payload;
    },

    SET_RG_BACK(state, payload) {
      state.complaintData.rg_back = payload;
    },

    SET_REGISTER_DOCUMENT(state, payload) {
      state.complaintData.register_document = payload;
    },

    SET_PROOF_ADDRESS(state, payload) {
      state.complaintData.proof_of_address = payload;
    },

    SET_ATTACHMENTS(state, payload) {
      state.complaintData.attachments.push(payload);
    },

    GET_ESTABLISHMENT_TYPES(state, payload) {
      state.establishmentTypes = payload;
    },

    REMOVE_ATTACHMENT(state, position) {
      state.complaintData.attachments.splice(position, 1);
    },

    SET_FINISH_COMPLAINT(state, payload){
      state.finishComplaint = payload;
    },

    RESET_COMPLAINT_DATA(state) {
      Object.keys(state.complaintData).forEach((key)=>{
        if(key === 'attachments') state.complaintData[key] = [];
        else state.complaintData[key] = null;
      });
    },

    RESET_FINISH_COMPLAINT(state) {
      Object.keys(state.finishComplaint).forEach((key)=>{
        state.finishComplaint[key] = null;
      });
    },
  }
};
