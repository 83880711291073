import Vue from 'vue';
import Vuex from 'vuex';
import keycloak from './modules/keycloak';
import general from './modules/general';
import complaints from './modules/complaints';
import shortcut from './modules/shortcut';
import onboarding from './modules/onboarding';
import priceSurvey from './modules/price-survey';
import modals from './modules/modals';
import autoLogin from './modules/autoLogin';
import news from './modules/news';
import appointment from './modules/appointment';
import errorModal from './modules/errorModal';
import consult from './modules/consult';
import survey from './modules/survey';
import service from './modules/manifestation/services';
import ticket from './modules/manifestation/ticket';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    keycloak,
    general,
    complaints,
    shortcut,
    onboarding,
    priceSurvey,
    modals,
    autoLogin,
    news,
    appointment,
    errorModal,
    consult,
    service,
    ticket,
    survey
  },
  plugins: [
    createPersistedState({
      key: 'Procon'
    })
  ]
});
