import api from '@/api/shortcut';

export default {
  state: {
    shortcuts: [],
    shortcutsNormalized: []
  },
  getters: {
    getShortcuts: state => state.shortcuts,
    shortcutsNormalized: state => state.shortcutsNormalized
  },
  actions: {
    async getAllShortcuts({ commit }) {
      try {
        const response = await api.getShortcuts();

        commit('SET_SHORTCUTS', response.data);

        return response.data;
      } catch (error) {
        commit('SET_SHORTCUTS', null);
      }
    },
    setShortcutsNormalized({ commit }, shortcuts) {
      commit('SET_SHORTCUTS_NORMALIZED', shortcuts);
    },
  },
  mutations: {
    SET_SHORTCUTS(state, payload) {
      state.shortcuts = payload;
    },
    SET_SHORTCUTS_NORMALIZED(state, payload) {
      state.shortcutsNormalized = payload;
    }
  }
};
