export default {
  state: {
    showOnboarding: false,
    step: 1,
    tourGuide: [],
    tourIndex: 0,
    finishedTourPage: false,
  },
  getters: {
    showOnboarding: state => state.showOnboarding,
    step: state => state.step,
    finalStep: state => state.tourGuide.reduce((count, obj) => {
      var counter = count;

      counter += obj.length;

      return counter;
    }, 0),
    tourGuide: state => state.tourGuide,
    finishedTourPage: state => state.finishedTourPage,
  },
  actions: {
    getTourGuide({ state }) {
      return state.tourGuide[state.tourIndex];
    },
    // getTourGuide({ state }) {
    //   return state.tourGuide;
    // },
    setOnboadingShow({ commit }, payload) {
      commit('SET_ONBOARDING_MODAL', payload);
    },
    setFinishedTourPage({ commit }, payload) {
      commit('SET_FINISHED_TOUR_PAGE', payload);
    },
    clearOnboardingModal({ commit }) {
      commit('CLEAR_ONBOARDING_MODAL');
    },
    clearTourGuide({ commit }) {
      commit('CLEAR_TOURGUIDE');
    },
    incrementStep({ commit }) {
      commit('INCREMENT_STEP');
    },
    incrementTourIndex({ commit }) {
      commit('INCREMENT_TOUR_INDEX');
    },
    initializeTourGuide({ commit }) {
      commit('INITIALIZE_TOUR_GUIDE');
      commit('SET_ONBOARDING_MODAL', true);
      commit('SET_FINISHED_TOUR_PAGE', false);
    },
    finalizeTourGuide({ commit }) {
      commit('CLEAR_TOURGUIDE');
      commit('SET_ONBOARDING_MODAL', false);
      commit('SET_FINISHED_TOUR_PAGE', true);
    },
  },
  mutations: {
    SET_ONBOARDING_MODAL(state, payload) {
      state.showOnboarding = payload;
    },
    SET_FINISHED_TOUR_PAGE(state, payload) {
      state.finishedTourPage = payload;
    },
    CLEAR_ONBOARDING_MODAL(state) {
      state.showOnboarding = null;
    },
    INCREMENT_STEP(state) {
      state.step += 1;
    },
    INCREMENT_TOUR_INDEX(state) {
      state.tourIndex += 1;
    },
    INITIALIZE_TOUR_GUIDE(state) {
      state.tourGuide = [
        [
          {
            target: '#onlineComplaintButton_0',
            title: 'Nova reclamação',
            content: 'Aqui você cadastra uma nova reclamação sobre algum produto ou serviço.',
            offset: -600,
            duration: 100,
            params: {
              placement: 'top'
            },
            redirect: 'NewReclamation',
            isLastInTour: false,
          }
        ],
        [
            {
            target: '.fileInput-0 .fileInput__addIcon',
            title: 'Anexar documentos',
            content: 'No primeiro passo você vai precisar anexar seus documentos pessoais para serem analisados.',
            offset: -600,
            duration: 100,
            params: {
              placement: 'top'
            },
            redirect: 'NextStep',
            isLastInTour: false,
          },
        ],
        [
          {
            target: '.input-mask__input',
            title: 'Dados do fornecedor',
            content: 'Preencha as informações da empresa ou fornecedor para que possamos identificá-la corretamente e notificar sobre sua reclamação.',
            offset: -600,
            duration: 100,
            params: {
              placement: 'bottom'
            },
            redirect: null,
            isLastInTour: false,
          },
          {
            target: '.companyData__services__inputs--input.id-7 input',
            title: 'Motivo da reclamação',
            content: 'Descreva detalhadamente o problema, incluindo datas, valores e detalhes relevantes. Também anexe todos os comprovantes que tiver para ajudar na análise da sua reclamação.',
            offset: -600,
            duration: 100,
            params: {
              placement: 'bottom'
            },
            redirect: 'NextStep',
            isLastInTour: false,
          },
        ],
        [
          {
            target: '.finish__card__info.complaint-data',
            title: 'Confirme os dados',
            content: 'Revise todas as informações fornecidas para garantir que estão corretas e completas.',
            offset: -600,
            duration: 100,
            params: {
              placement: 'bottom'
            },
            redirect: 'OnlineComplaint',
            isLastInTour: false,
          },
        ],
        [
          {
            target: '#onlineComplaintButton_1 .card__header__icon',
            title: 'Acompanhe o andamento',
            content: 'Após enviar sua reclamação, você pode acompanhar o status e as atualizações diretamente no sistema.',
            offset: -600,
            duration: 100,
            params: {
              placement: 'top'
            },
            redirect: 'MyComplaints',
            isLastInTour: false,
          },
        ],
        [
          {
            target: '.single-row',
            title: 'Consulte reclamações',
            content: 'Aqui você pode visualizar todas as suas reclamações registradas. Acompanhe o status e as atualizações de cada caso em um único lugar.',
            offset: -600,
            duration: 100,
            params: {
              placement: 'bottom'
            },
            redirect: 'ComplaintDetails',
            isLastInTour: false,
          },
        ],
        [
          {
            target: '.update__info',
            title: 'Tudo em um único lugar!',
            content: 'Aqui você encontra todos os detalhes da sua reclamação. Use essa seção para monitorar o progresso, verificar atualizações e responder a mensagens adicionais.',
            offset: -600,
            duration: 100,
            params: {
              placement: window.innerWidth >= 1024 ? 'left' : 'top'
            },
            redirect: 'Home',
            isLastInTour: true,
          },
        ],
      ];
    },
    CLEAR_TOURGUIDE(state) {
      state.tourGuide = [];
      state.step = 1;
      state.tourIndex = 0;
      state.showOnboarding = false;
    }
  }
};
