import manifestationApi from '@/api/manifestation';

export default {
  state: {
    subject: null,
    searchData: null,
  },

  getters: {
    selectedSubject: (state) => state.subject,
    getSearchData: (state) => state.searchData,
  },

  actions: {
    async getSingleSubject({ commit }, id) {
      try{
        const response = await manifestationApi.getSubjectData(id);

        commit('SET_SELECTED_SUBJECT', response.data.data);
      }
      catch (e){
        commit('SET_SELECTED_SUBJECT', null);
      }
    },
  },

  async getSearch({ commit } ){
    try{
      const response = await manifestationApi.getSearchData();

      commit('SET_SEARCH_DATA', response.data.data);

      return response.data;
    }
    catch (e){
      commit('SET_SEARCH_DATA', null);
    }
  },

  mutations: {
    SET_SELECTED_SUBJECT(state, payload){
      state.subject = payload;
    },

    SET_SEARCH_DATA(state, payload){
      state.searchData = payload;
    },
  }
};