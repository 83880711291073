import Keycloak from '@/api/keycloak.js';

export default {
  state: {
    autoLoginData: null
  },

  getters: {
    userAutoLoginData: state => state.autoLoginData
  },

  actions: {
    userSetAutoLoginData({ commit }, { origin, token, refreshToken, clientId }) {
      commit('USER_SET_AUTO_LOGIN', { origin, token, refreshToken, clientId });
    },

    async userAutoLoginKeycloak({ commit }, data) {
      try {
        const userInfo = await Keycloak.autologinKeycloak(
          data.token,
          data.refreshToken,
          data.clientId
        );

        commit('KEYCLOAK_INIT', userInfo);

        if (userInfo.authenticated) {
          commit('KEYCLOAK_LOGIN', userInfo);
        }
      } catch (e) {
        commit('KEYCLOAK_INIT', { keycloak: null });
      }
    },

    userResetAutoLoginData({ commit }) {
      commit('USER_SET_AUTO_LOGIN', null);
    }
  },

  mutations: {
    USER_SET_AUTO_LOGIN(state, payload) {
      state.autoLoginData = payload;
    }
  }
};
